<template>

  <div class="container">
		<div class="row" v-if="!edit">

      <div class="col-12 col-lg-9 subnav">
        <router-link to="/hallinta/sopimusasiakkaat"><h1>Sopimusasiakkaat</h1></router-link>
				<router-link to="/hallinta/kohteet"><h1>Kohteet</h1></router-link>
        <router-link to="/hallinta/asukkaat"><h1>Asukkaat</h1></router-link>
        <router-link to="/hallinta/huoltomiehet"><h1>Huoltomiehet</h1></router-link>
			</div>
			<div class="col-12 col-lg-3 text-right">
				<button class="btn btn-new btn-lg d-inline-block" v-if="!loading" v-on:click.stop.prevent="newitem"><i class="fas fa-plus"></i> Luo uusi kohde</button>
			</div>

			<div class="col-12">

				<b-overlay v-if="loading" class="list-loading" variant="white" show no-wrap></b-overlay>

        <div class="filters">
          <div class="filter">
            <select v-model="filters.owner_id">
              <option value="">Sopimusasiakas</option>
              <option v-for="(client) in clients" :key="client.id" :value="client.id">{{ client.fullname }}</option>
            </select>
          </div>
          <div class="filter">
            <select v-model="filters.area">
              <option value="">Alue</option>
              <option v-for="(area) in areas" :key="area.id" :value="area.id">{{ area.name }}</option>
            </select>
          </div>
          <div class="filter">
            <input type="text" v-model="filters.zip" class="form-control form-control-sm" placeholder="Postinumero tai osoite" />
          </div>
          <div class="filter">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="empty" value="1" v-model="filters.empty">
              <label class="custom-control-label" for="empty">Vain, jossa ei asukkaita</label>
            </div>
          </div>
          <button type="button" class="btn btn-sm btn-primary" v-on:click="load()">Suodata</button>
				</div>

				<b-alert variant="danger" :show="error? true : false">{{ error }}</b-alert>

        <table class="admin-list" v-if="!loading && visible > 0">
					<tr>
						<th>ID <i class="fas fa-chevron-down" v-on:click="sort = 'id:asc'" v-if="sort != 'id:asc'"></i> <i class="fas fa-chevron-up" v-on:click="sort = 'id:desc'" v-if="sort == 'id:asc'"></i></th>
						<th>Nimi <i class="fas fa-chevron-down" v-on:click="sort = 'name:asc'" v-if="sort != 'name:asc'"></i> <i class="fas fa-chevron-up" v-on:click="sort = 'name:desc'" v-if="sort == 'name:asc'"></i></th>
						<th>Osoite</th>
						<th>Sop.asiakas</th>
						<th>Asukkaita</th>
						<th></th>
					</tr>
					<tr v-for="(item) in items" :key="item.id" :id="['site-' + item.id]">
						<td class="w120"><p><strong>{{ item.id }}</strong></p></td>
						<td class=""><p><strong>{{ item.name }}</strong></p></td>
						<td class="text"><p v-html="displayAddress(item.address)"></p></td>
						<td class=""><p><router-link :to="{ name: 'Sopimusasiakkaat', query: { id: item.owner_id } }">{{ getClient(item.owner_id) }}</router-link></p></td>
            <td class=""><p><strong>{{ item.residents }}</strong></p></td>
						<td class="text-right"><a class="btn btn-sm btn-primary" v-on:click.stop.prevent="edititem(item.id)">Näytä</a></td>
					</tr>
				</table>

				<b-alert class="my-3" variant="info" :show="!loading && items.length == 0">Kohteita ei löydetty!</b-alert>

				<ul class="pagination pagination-sm justify-content-center my-4" v-if="!loading && pages > 0">
					<li v-for="n in pages" :key="n" class="page-item" :class="[page == (n-1) ? 'active' : '']"><a class="page-link" href="#" v-on:click.stop.prevent="showpage(n-1)">{{ n }}</a></li>
				</ul>

			</div>
		</div>
		<div class="row" v-if="edit">
			<div class="col-12 pt-1">
        <b-overlay variant="white" :show="sending" no-wrap></b-overlay>
        <form @submit.prevent="saveedit">
					<div class="row">
						<div class="col-12 col-lg-6">
              <h1 v-if="typeof edit_data.id == 'undefined'">Uusi kohde</h1>
              <h1 v-if="typeof edit_data.id != 'undefined'">{{ edit_data.name }} ({{ edit_data.id }})</h1>

							<div class="form-group">
								<label class="form-label">Nimi</label>
                <input v-model="edit_data.name" class="form-control" required />
							</div>

							<div class="form-group">
								<label class="form-label">Osoite</label>
                <input v-model="edit_data.address" class="form-control" required />
							</div>

              <div class="form-group col-lg-8 px-0">
								<label class="form-label">Kohteen tyyppi</label>
                <select v-model="edit_data.site_type" class="form-control" required>
                  <option value="">- valitse tyyppi -</option>
                  <option value="1">Kerrostalo</option>
                  <option value="2">Rivitalo</option>
                  <option value="3">Liikehuoneisto</option>
                </select>
							</div>

              <div class="form-group col-lg-8 px-0">
                <label class="form-label">Alue</label>
                <select v-model="edit_data.area" class="form-control" required>
                  <option value="">- valitse alue -</option>
                  <option v-for="(area) in areas" :key="area.id" :value="area.id">{{ area.name }}</option>
                </select>
              </div>

              <div class="form-group col-lg-8 px-0">
                <label class="form-label">Sopimusasiakas</label>
                <select v-model="edit_data.owner_id" class="form-control" required>
                  <option value="">- valitse sopimusasiakas -</option>
                  <option v-for="(client) in clients" :key="client.id" :value="client.id">{{ client.fullname }}</option>
                </select>
              </div>

							<div class="form-group">
								<label class="form-label">Lisätiedot</label>
								<textarea v-model="edit_data.info" class="form-control"></textarea>
							</div>

              <div class="form-group mt-5 position-relative" v-if="typeof edit_data.id != 'undefined'">
                <h3>Muistiot</h3>
                <b-overlay variant="white" :show="memos_loading" no-wrap></b-overlay>


                <div class="d-none"><input class="d-block mt-2" type="file" ref="file" @change="selectFile" /></div>

                <div class="alert alert-info" v-if="memos.length == 0">Kohteella ei ole muistioita.</div>
                <div class="memos position-relative">

                  <b-overlay :show="confirmDeleteImage" variant="white" no-wrap >
                    <template #overlay>
                      <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label" class="text-center dialog-mw" >
                        <p><strong id="form-confirm-label">Haluatko varmasti poistaa kuvan?</strong></p>
                        <div class="text-center">
                          <b-button variant="outline-danger" class="mr-3" @click="confirmDeleteImage = false">Peruuta</b-button>
                          <b-button variant="outline-success" @click="deleteMemoImage()">OK</b-button>
                        </div>
                      </div>
                    </template>
                  </b-overlay>

                  <b-overlay :show="confirmDeleteMemo" variant="white" no-wrap>
                    <template #overlay>
                      <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label" class="text-center dialog-mw" >
                        <p><strong id="form-confirm-label">Haluatko varmasti poistaa muistion?</strong></p>
                        <div class="text-center">
                          <b-button variant="outline-danger" class="mr-3" @click="confirmDeleteMemo = false">Peruuta</b-button>
                          <b-button variant="outline-success" @click="deleteMemo()">OK</b-button>
                        </div>
                      </div>
                    </template>
                  </b-overlay>

                  <b-overlay :show="image_memoid > 0" variant="white" no-wrap>
                    <template #overlay>
                      <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label" class="text-center dialog-mw" >
                        <h3>Lisää kuva</h3>
                        <div class="form-group">
                          <label class="form-label">Kuvan otsikko</label>
                          <input type="text" class="form-control" v-model="image_title" />
                        </div>
                        <div class="form-group">
                          <button type="button" class="btn btn-secondary" @click.stop.prevent="$refs.file.click()">{{ image_filename ? image_filename : 'Valitse tiedosto' }}</button>
                        </div>
                        <div class="text-center">
                          <b-button variant="outline-danger" class="mr-3" @click="image_memoid = 0">Peruuta</b-button>
                          <b-button variant="outline-success" @click="saveMemoImage()">TALLENNA</b-button>
                        </div>
                      </div>
                    </template>
                  </b-overlay>

                  <div class="accordion" role="tablist">

                    <b-card no-body v-for="(memo) in memos" :key="memo.id">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle="'accordion-' + memo.id" variant="primary">{{ memo.title }} {{ memo.created | formatDate }}</b-button>
                      </b-card-header>
                      <b-collapse :id="'accordion-' + memo.id" accordion="my-accordion" role="tabpanel">
                        <b-card-body>

                          <p><strong>Muistion sisältö</strong></p>
                          <p class="pre-wrap" v-html="memo.content"></p>
                          <p class="small">{{ memo.updated_by_name }} - {{ memo.updated | formatDateTimePretty }}</p>

                          <div class="row file-list" v-if="typeof memo.files != 'undefined' && memo.files.length > 0">
                            <div class="col-12"><p><strong>Kuvat</strong></p></div>
                            <div class="col-6 file" v-for="(file) in memo.files" :key="file.id" >
                              <img :src="['/api/sitefiles/' + file.id]" :alt="file.filename" v-on:click="showMemoImage(file)" />
                              <p class="blue small"><strong>{{ file.title }}</strong><br/>{{ file.fullname }}<br/>{{ file.created | formatDateTime }} <i class="fas fa-trash ml-2" v-on:click="confirmDeleteMemoImage(file.id)"></i></p>
                            </div>
                          </div>

                          <p class="mt-3 mb-0"><span class="cursor-pointer" v-on:click="image_memoid = memo.id"><i class="fas fa-images"></i> Lisää kuva</span> <span class="ml-2 cursor-pointer" v-on:click="editMemo(memo)"><i class="fas fa-edit"></i> Muokkaa</span> <span class="ml-2 cursor-pointer" v-on:click="confirmDeleteMemos(memo.id)"><i class="fas fa-trash"></i> Poista</span></p>

                        </b-card-body>
                      </b-collapse>
                    </b-card>

                  </div>
                </div>

                <div class="form-group mt-4" ref="memoForm">
                  <h3 v-if="typeof memo_data.id == 'undefined'">Uusi muistio</h3>
                  <h3 v-if="typeof memo_data.id != 'undefined'">Muokkaa muistiota #{{ memo_data.id }}</h3>
                  <div class="form-group">
                    <label class="form-label">Otsikko</label>
                    <input type="text" v-model="memo_data.title" class="form-control" />
                  </div>
                  <div class="form-group">
                    <label class="form-label">Sisältö</label>
                    <textarea v-model="memo_data.content" class="form-control"></textarea>
                  </div>
                  <div class="form-group text-right">
                    <button type="button" class="btn btn-light ml-2" v-if="typeof memo_data.id != 'undefined'" v-on:click="newMemo()">PERUUTA</button>
                    <button type="button" class="btn btn-new ml-2" v-on:click="saveMemo()"><i class="fas fa-save"></i> TALLENNA</button>
                  </div>
                </div>

              </div>

						</div>

            <div class="col-12 col-lg-5 offset-lg-1">
              <div class="form-group text-right">
                <button type="button" class="btn btn-delete" v-if="typeof edit_data.id != 'undefined'" @click="confirmDelete = true"><i class="fas fa-trash"></i> POISTA</button>
                <button type="button" class="btn btn-light btn-lg ml-2" v-if="typeof edit_data.id != 'undefined'" v-on:click="canceledit()">TAKAISIN</button>
                <button type="button" class="btn btn-light btn-lg ml-2" v-if="typeof edit_data.id == 'undefined'" v-on:click="canceledit()">PERUUTA</button>
                <button type="submit" class="btn btn-new btn-lg ml-2"><i class="fas fa-save"></i> TALLENNA</button>
              </div>

              <div class="form-group row mt-5">
                <div class="col-12 check-wrap">
                  <label class="form-label">Kohteen asukkaat</label>
                  <div class="custom-control custom-checkbox" v-if="edit_data.can_register == 0 || edit_data.register_id == null">
                    <input type="checkbox" class="custom-control-input" id="can_register" value="1" v-model="edit_data.can_register">
                    <label class="custom-control-label" for="can_register">Luo yksilöllinen URL rekisteröitymiseen</label>
                  </div>
                  <div class="form-group" v-if="edit_data.can_register == 1 && edit_data.register_id != null">
                    <p>Rekisteröityminen:<br> <router-link target="_blank" :to="{ path: '/luo-tunnus/' + edit_data.register_id }">{{ url }}/luo-tunnus/{{ edit_data.register_id }}</router-link></p>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <table class="admin-list" v-if="edit_data.can_register == 1 && edit_data.register_id != null">
                  <tr v-for="(user) in edit_data.users" :key="user.id" :id="['user-' + user.id]">
                    <td class="w120"><p><strong>{{ user.id }}</strong></p></td>
                    <td class=""><p><router-link :to="{ name: 'Asukkaat', query: { id: user.id } }">{{ user.extended.lastname }}, {{ user.extended.firstname }}</router-link></p></td>
                    <td class=""><p>{{ user.city }}</p></td>
                  </tr>
                </table>
              </div>

            </div>

					</div>
				</form>

				<b-overlay :show="confirmDelete" variant="white" no-wrap no-center>
					<template #overlay>
						<div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label" class="text-center p-5" >
							<p><strong id="form-confirm-label">Haluatko varmasti poistaa kohteen "{{ edit_data.name }}" ?</strong></p>
							<div class="text-center">
								<b-button variant="outline-danger" class="mr-3" @click="confirmDelete = false">Peruuta</b-button>
								<b-button variant="outline-success" @click="senddelete()">OK</b-button>
							</div>
						</div>
					</template>
				</b-overlay>

        <b-overlay variant="white" :show="uploading" no-wrap>
          <template #overlay>
            <div class="text-center text-dark p-4">
              <div class="mb-3">Siirretään tiedostoja...</div>
                <b-progress
                  min="1"
                  max="100"
                  :value="progress"
                  variant="success"
                  height="10px"
                  class="mx-n4 rounded-0"
                ></b-progress>
              </div>
            </template>
        </b-overlay>

        <b-modal id="image-modal" size="xl" centered v-model="imageModal"><img :src="imageModalImg" /><p class="mt-4" v-html="imageModalInfo"></p></b-modal>

			</div>
		</div>
	</div>

</template>

<script>
export default {
	data: function () {
		return {
			loading: true,
			error: false,
			items: [],
      clients: [],
      areas: [],
      filters: {
        owner_id: '',
        area: '',
        zip: '',
        empty: false,
      },
			edit: false,
			confirmDelete: false,
			edit_data: [],
      memos_loading: false,
      memos: [],
      memo_data: { title: '', content: '' },
      image_title: '',
      image_memoid: 0,
      image_filename: false,
			sending: false,
      uploading: false,
      progress: 0,
			visible: 0,
			perpage: 10,
			pages: 0,
			page: 0,
			sort: 'id:desc',
      imageModal: false,
      imageModalImg : '',
      imageModalInfo : '',
      confirmDeleteImage: false,
      deleteImageId: 0,
      confirmDeleteMemo: false,
      deleteMemoId: 0,
		}
	},
	created: function () {
    var vm = this;
		this.axios.get('/api/users?type=clients&limit=500')
		.then(function (response) {
			if(response.status == 200) {
				vm.clients = response.data.results;
			}
		})
		.catch(function (error) {
			console.log(error);
		})
    this.axios.get('/api/filters?limit=500')
		.then(function (response) {
			if(response.status == 200) {
        (response.data.results).forEach(filter => {
          if(filter.type == 1) vm.areas.push(filter);
          //else if(filter.type == 2) vm.types.push(filter);
        })
			}
		})
		.catch(function (error) {
			console.log(error);
		})
    vm.load();
    if(typeof vm.$route.query.id != 'undefined') {
			vm.edititem(this.$route.query.id);
		}
	},
	methods: {
		encode: function(str) {
      //<router-link target="_blank" :to="{ path: '/luo-tunnus', query: { site: item.id, h: encode('plushuolto-'+item.id) }}">Linkki</router-link>
			return btoa(str);
		},
    getClient: function(s_id) {
      const result = this.clients.find( ({ id }) => id === s_id );
      if(result) return result.fullname;
      return s_id;
    },
    displayAddress: function(addr) {
      return addr.split(",").join("<br/>");
    },
		load: function () {
			var vm = this;
      vm.error = false;
			vm.loading = true;
			var sort = vm.sort.split(':');
			this.axios.get('/api/sites?limit=' + vm.perpage + '&start=' + (vm.page * vm.perpage) + '&sort=' + sort[0] + '&dir=' + sort[1] + '&filters=' + JSON.stringify(vm.filters))
			.then(function (response) {
				if(response.status == 200) {
					vm.loading = false;
					vm.pages = Math.ceil(response.data.total / vm.perpage);
					vm.items = response.data.results;
					vm.visible = response.data.results.length;
				} else {
					vm.error = response.data.message;
				}
			})
			.catch(function (error) {
				vm.error = error;
				vm.items = [];
				vm.pages = 0;
				vm.loading = false;
			})
		},
		showpage: function (id) {
			this.page = id;
		},
    edititem: function (id) {
      var vm = this;
      vm.edit = true;
      vm.sending = true;
      //this.edit_data = Object.assign({}, this.items[id]);

      this.axios.get('/api/sites/' + id)
      .then(function (response) {
        if(response.data.success) {
          vm.edit_data = response.data.object;
          vm.sending = false;
          vm.loadMemos(response.data.object.id);
        } else {
          vm.$parent.makeToast('danger', 'Kohteen lataus', 'Epäonnistui!');
          vm.edit = false;
          vm.sending = false;
        }
      })
      .catch(function (error) {
        console.log(error);
        vm.$parent.makeToast('danger', 'Kohteen lataus', 'Epäonnistui!');
        vm.edit = false;
        vm.sending = false;
      })
    },
    loadMemos: function (id) {
      var vm = this;
      vm.memos = [];
      vm.memos_loading = true;

      this.axios.get('/api/sitememos?site_id=' + id)
      .then(function (response) {
        if(response.status == 200) {
          vm.memos = response.data.results;
          vm.memos_loading = false;
        } else {
          vm.$parent.makeToast('danger', 'Kohteen lataus', 'Epäonnistui!');
          vm.memos = [];
          vm.memos_loading = false;
        }
      })
      .catch(function (error) {
        console.log(error);
        vm.$parent.makeToast('danger', 'Kohteen lataus', 'Epäonnistui!');
        vm.memos = [];
        vm.memos_loading = false;
      })
    },
		canceledit: function () {
			this.edit = false;
		},
		newitem: function () {
			this.edit = true;
			this.edit_data = { name: '', address: '', info: '', owner_id: '', site_type: '', area: '', can_register: 0 }
		},
		senddelete: function () {
			var vm = this;
      vm.sending = true;
      vm.confirmDelete = false;
			this.axios({
				method: 'delete',
				url: '/api/sites',
				data: vm.edit_data
			})
			.then(function (response) {
				if(response.data.success) {
					vm.$parent.makeToast('success', 'Kohteen poisto', 'Onnistui!');
					vm.load();
					vm.edit = false;
				} else {
					vm.$parent.makeToast('danger', 'Kohteen poisto', 'Epäonnistui!');
				}
        vm.sending = false;
			})
			.catch(function () {
				vm.$parent.makeToast('danger', 'Kohteen poisto', 'Epäonnistui!');
        vm.sending = false;
			})
		},
		saveedit: function () {
			var vm = this;
			if(!vm.sending) {
				vm.sending = true;
				var method = 'put';
				if(typeof vm.edit_data.id == 'undefined') method = 'post';
				this.axios({
					method: method,
					url: '/api/sites',
					data: vm.edit_data
				})
				.then(function (response) {
					if(response.data.success) {
						//if(typeof vm.edit_data.id == 'undefined' && response.data.object.id > 0) vm.edit_data.id = response.data.object.id;
            vm.edit_data = response.data.object;

						vm.$parent.makeToast('success', 'Kohteen tallennus', 'Onnistui!');
						vm.load();
					} else {

						vm.$parent.makeToast('warning', 'Kohteen tallennus', 'Täytä pakolliset kentät!');
					}
					vm.sending = false;
				})
				.catch(function () {
					vm.sending = false;
					vm.$parent.makeToast('danger', 'Kohteen tallennus', 'Epäonnistui!');
				})
			}
		},
    editMemo: function ( memo ) {
      var vm = this;
      vm.memo_data = { id: memo.id, title: memo.title, content: memo.content };

      const el = this.$refs.memoForm;
      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
      }

    },
    newMemo: function () {
      this.memo_data = { title: '', content: '' };
      this.image_memoid = 0;
    },
    saveMemo: function () {
			var vm = this;
			if(!vm.sending) {
				vm.sending = true;
				var method = 'put';
				if(typeof vm.memo_data.id == 'undefined') method = 'post';
        vm.memo_data.site_id = vm.edit_data.id;
				this.axios({
					method: method,
					url: '/api/sitememos',
					data: vm.memo_data
				})
				.then(function (response) {
					if(response.data.success) {
						vm.loadMemos(vm.edit_data.id);
            vm.newMemo();
						vm.$parent.makeToast('success', 'Muistion tallennus', 'Onnistui!');
					} else {
						vm.$parent.makeToast('warning', 'Muistion tallennus', 'Täytä pakolliset kentät!');
					}
					vm.sending = false;
				})
				.catch(function () {
					vm.sending = false;
					vm.$parent.makeToast('danger', 'Muistion tallennus', 'Epäonnistui!');
				})
			}
		},
    confirmDeleteMemos: function ( memo ) {
      this.deleteMemoId = memo;
      this.confirmDeleteMemo = true;
    },
    deleteMemo: function () {
			var vm = this;
      vm.sending = true;
      vm.confirmDeleteMemo = false;
			this.axios({
				method: 'delete',
				url: '/api/sitememos/' + vm.deleteMemoId
			})
			.then(function (response) {
				if(response.data.success) {
					vm.$parent.makeToast('success', 'Muistion poisto', 'Onnistui!');
					vm.loadMemos(vm.edit_data.id);
				} else {
					vm.$parent.makeToast('danger', 'Muistion poisto', 'Epäonnistui!');
				}
        vm.sending = false;
			})
			.catch(function () {
				vm.$parent.makeToast('danger', 'Muistion poisto', 'Epäonnistui!');
        vm.sending = false;
			})
    },
    saveMemoImage: function() {
      var vm = this;

      let file = false;
      for( var i = 0; i < vm.$refs.file.files.length; i++ ){
				file = vm.$refs.file.files[i];
			}

      if(!file || vm.image_memoid == 0 || vm.image_memotitle == '') return;

      vm.uploading = true;
      vm.progress = 0;

      let formData = new FormData();
      formData.append('memo_id', vm.image_memoid);
      formData.append('title', vm.image_title);
      formData.append('file', file);

      this.axios.post('/api/sitefiles', formData, {
				headers: {
          'Content-Type': 'multipart/form-data'
				},
				onUploadProgress: progressEvent => {
					vm.progress = Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 );
				}
			})
			.then(function (response) {
        if(response.data.success) {
          vm.$parent.makeToast('success', 'Tiedostojen siirto', 'Onnistui!');
          vm.uploading = false;
          vm.image_title = '';
          vm.$refs.file.value = null;
          vm.selectFile();
          vm.loadMemos(vm.edit_data.id);
          vm.image_memoid = 0;
        } else {
          vm.$parent.makeToast('danger', 'Tiedostojen siirto', 'Epäonnistui!');
          vm.uploading = false;
        }
			})
			.catch(function () {
				vm.$parent.makeToast('danger', 'Tiedostojen siirto', 'Epäonnistui!');
				vm.uploading = false;
			});

    },
    showMemoImage: function(image) {
      var vm = this;
      vm.imageModalImg = ['/api/sitefiles/' + image.id];
      vm.imageModalInfo = '<strong>' + image.title + '</strong><br/>' + image.fullname + ' - ' + vm.$options.filters.formatDateTime(image.created);
      vm.imageModal = true;
    },
    confirmDeleteMemoImage: function ( image ) {
      this.deleteImageId = image;
      this.confirmDeleteImage = true;
    },
    deleteMemoImage: function () {
			var vm = this;
      vm.sending = true;
      vm.confirmDeleteImage = false;
			this.axios({
				method: 'delete',
				url: '/api/sitefiles/' + vm.deleteImageId
			})
			.then(function (response) {
				if(response.data.success) {
					vm.$parent.makeToast('success', 'Kuvan poisto', 'Onnistui!');
					vm.loadMemos(vm.edit_data.id);
				} else {
					vm.$parent.makeToast('danger', 'Kuvan poisto', 'Epäonnistui!');
				}
        vm.sending = false;
			})
			.catch(function () {
				vm.$parent.makeToast('danger', 'Kuvan poisto', 'Epäonnistui!');
        vm.sending = false;
			})
    },
    selectFile: function () {
      this.image_filename = false;
      if(typeof this.$refs.file.files[0] != 'undefined') this.image_filename = this.$refs.file.files[0].name;
    }
	},
  computed: {
    url: function() {
      return window.location.origin;
    }
  },
  watch: {
		page: function () {
			this.load();
		},
    sort: function () {
			this.load();
		},
	}
}
</script>
